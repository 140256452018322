import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { navigate } from "gatsby"

import Layout from "../components/Layout"
import Seo from "../components/Seo"
import Footer from "../components/Footer"
import DynamicComponent from "../components/DynamicComponent"
import useStoryblok from "../lib/storyblok"

const test = {
  about:
    "Shopsmart you can easily store and manage all your cards in one app and browse the latest offers from your favourite retailers and brands. Deals and e-commerce will allow you to get offers and rewards",
  home:
    "Shopsmart Loyalty Card and E-Wallet. With Shopsmart you can easily store and manage all your cards in one app and browse the latest offers from your favourite retailers and brands",
}

const Page = ({ pageContext, location }) => {
  let story = pageContext.story
  story = useStoryblok(story, location)

  const description = test[story.name]

  const FooterComponent = story.content.body
    .filter(item => item.component === "Footer")
    .map(blok => {
      return <Footer blok={blok} key={blok._uid} />
    })

  const components = story.content.body.map(blok => {
    return <DynamicComponent blok={blok} key={blok._uid} />
  })

  return (
    <Layout location={location}>
      <Seo title="Home" description={description} />
      {components}
      {FooterComponent}
    </Layout>
  )
}

export default Page
